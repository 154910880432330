for num in 1..20
	.mb-{num * 5}
		margin-bottom: num * 5px!important
		&-md
			@media screen and (min-width: 1024px)
				margin-bottom: num * 5px!important
	.mt-{num * 5}
		margin-top: num * 5px!important
		&-md
			@media screen and (min-width: 1024px)
				margin-top: num * 5px!important
for num in 1..20
	.ml-{num * 5}
		margin-left: num * 5px!important
	.mr-{num * 5}
		margin-right: num * 5px!important
		&-md
			@media screen and (min-width: 1024px)
				margin-right: num * 5px!important
.margin-auto
	margin 0  auto !important
.no-padding
	padding:0!important
.no-margin
	margin:0!important
	&-md
		+from-width(5)
			margin:0!important
.no-margin-top
	margin-top:0!important
	&-md
		+from-width(5)
			margin-top:0!important
.clearfix
	&:after,&:before
		content ''
		display block
		clear both
.center-block
	display:block
	margin:0 auto
.visible
	&-xs,&-sm,&-md,&-lg
		display:none!important
	&-inline-xs,&-inline-sm,&-inline-md,&-inline-lg
		display:none!important
	&-flex-xs,&-flex-sm,&-flex-md,&-flex-lg
		display:none!important
	//@media only screen and (max-width: 767px)
	+between(0,3,anti-overlap:-1px)
		&-xs
			display:block!important
		&-inline-xs
			display:inline-block!important
		&-flex-xs
			display:flex!important
	//Tablets @media only screen and (min-width: 768px) and (max-width: 1023px)
	+from-width(4, anti-overlap:false)
		&-sm
			display:block!important
		&-inline-sm
			display:inline-block!important
		&-flex-sm
			display:flex!important
	//Small Desktops @media only screen and (min-width: 1024px)
	+from-width(5)
		&-md
			display:block!important
		&-inline-md
			display:inline-block!important
		&-flex-md
			display:flex!important
	//Desktops @media only screen and (min-width: 1201px)
	+from-width(6)
		&-lg
			display:block!important
		&-inline-lg
			display:inline-block!important
		&-flex-lg
			display:flex!important
.hidden
	display none!important
	+between(0,3,anti-overlap:-1px)
		&-xs
			display:none!important
	//Tablets ()
	+from-width(4, anti-overlap:false)
		&-sm
			display:none!important
	//small Desktops
	+from-width(5)
		&-md
			display:none!important
	// Desktops
	+from-width(6)
		&-lg
			display:none!important
//positions ----------------------------------------------
.movertransvuelo
	margin-top 11px !important
.block-center
	margin:0 auto
.pull-right
	float: right!important
.pull-left
	float: left!important
.uppercase
	text-transform uppercase
.lowercase
	text-transform capitalize!important
.text-center
	text-align:center !important
.text-left
	text-align:left!important
	+from-width(5)
		&-md
			text-align:left!important
.text-right
	text-align:right!important
	+from-width(5)
		&-md
			text-align:right!important
.capitalize
	text-transform capitalize
.c-pointer
	cursor pointer
// ICONOS
.icon
	vertical-align: middle
	line-break: 0
	line-height: 0
	&--xs
		font-size: 0.80em
		&-sub:before
			font-size: 0.80em
	&--sm
		font-size: 1em
		&-sub:before
			font-size: 1em
	&--md
		font-size: 1.25em
		&-sub:before
			font-size: 1.25em
	&--lg
		font-size: 1.5em
		&-sub:before
			font-size: 1.5em
	&--xl
		font-size: 1.75em
		&-sub:before
			font-size: 1.75em
	&--xll
		font-size: 2em
		&-sub:before
			font-size: 2em
.separator
	border:0px
	border-bottom: 0.5px solid rgba(68,68,68,0.2);
.border-right
	&-md
		+from-width(5)
			border-right: 0.5px solid rgba(68,68,68,0.2);