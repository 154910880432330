.login
  box-sizing: border-box
  min-height 100vh
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  position relative
  @media (min-width: 320px) and (max-width: 1024px)
    background: url('../../static/img/login.jpg') no-repeat
    background-position: center center
    background-size: cover
    padding: 2rem 0
    &:before
      position absolute
      content: ''
      height 100%
      width: 100%
      display block
      background: rgba(#fff,50%)
  &__brand
    max-width 200px
    padding: 2rem
    margin:0 auto
    text-align: center
    +from-width(5)
      margin: 2.5rem auto
      max-width 230px
  &__aside
    height 100vh
    display: none
    +from-width(5)
      // background: url('../../static/img/logos/isotipo.png') no-repeat
      background: url('../../static/img/login.jpg') no-repeat
      background-position: center center
      background-size: cover
      display: block
      flex:1
      flex-basis: 40%
      max-width: 40%
  &__form
    max-width 520px
    &__title
      color: #7F98A2
      font-size: 16px
      line-height: 22px
      text-align: center
      margin-bottom: 25px
  &__body
    padding: 1rem
    display: flex
    justify-content: center
    height 100%
    flex-basis: 100%
    width: 100%
    +from-width(5)
      flex-basis: 60%
      max-width: 60%