html {
  box-sizing: border-box;
  margin-right: 0px !important;
}
*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
a:focus {
  outline: none;
}
body::-webkit-scrollbar {
  display: none;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  background: none;
  outline: none;
  border: 0px;
}
button:focus {
  outline: none;
}
input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="submit"],
select,
textarea {
  outline: none;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="submit"]:focus,
select:focus,
textarea:focus {
  outline: none;
}
select:disabled,
input[type=text]:disabled,
input[type=number]:disabled,
input[type=password]:disabled,
input[type=date]:disabled {
  background: fade(#444, 90%);
  border-color: fade(#444, 90%);
  cursor: not-allowed;
  color: fade(#444, 90%);
}
select:disabled::placeholder,
input[type=text]:disabled::placeholder,
input[type=number]:disabled::placeholder,
input[type=password]:disabled::placeholder,
input[type=date]:disabled::placeholder {
  color: fade(#444, 90%);
}
select:focus {
  outline: none;
}
.danger-color {
  color: $orange-color;
}
.primary-color {
  color: #1890ff;
}
.primary-color-bg {
  background-color: #1890ff;
}
.btn-fix-width {
  min-width: 115px;
}
.btn-fix-width-excel {
  background-color: #fff;
  min-width: 165px;
  color: #227346;
  border: #227346 1px solid;
}
.btn-fix-width-excel:hover {
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  color: #227346;
  border: #227346 1px solid;
}
.btn-fix-width-excel:active {
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  color: #227346;
  border: #227346 1px solid;
}
.btn-fix-width-excel:focus {
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  color: #227346;
  border: #227346 1px solid;
}
.btn-fix-width-pdf {
  background-color: #fff;
  min-width: 165px;
  color: #ff0800;
  border: #ff0800 1px solid;
}
.btn-fix-width-pdf:hover {
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  color: #ff0800;
  border: #ff0800 1px solid;
}
.btn-fix-width-pdf:active {
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
  color: #ff0800;
  border: #ff0800 1px solid;
}
.btn-fix-width-pdf:focus {
  box-shadow: rgba(0,0,0,0.35) 0px 5px 15px !important;
  color: #ff0800 !important;
  border: #ff0800 1px solid !important;
}
.no-wrap {
  flex-flow: nowrap !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
@media screen and (min-width: 1024px) {
  .mb-5-md {
    margin-bottom: 5px !important;
  }
}
.mt-5 {
  margin-top: 5px !important;
}
@media screen and (min-width: 1024px) {
  .mt-5-md {
    margin-top: 5px !important;
  }
}
.mb-10 {
  margin-bottom: 10px !important;
}
@media screen and (min-width: 1024px) {
  .mb-10-md {
    margin-bottom: 10px !important;
  }
}
.mt-10 {
  margin-top: 10px !important;
}
@media screen and (min-width: 1024px) {
  .mt-10-md {
    margin-top: 10px !important;
  }
}
.mb-15 {
  margin-bottom: 15px !important;
}
@media screen and (min-width: 1024px) {
  .mb-15-md {
    margin-bottom: 15px !important;
  }
}
.mt-15 {
  margin-top: 15px !important;
}
@media screen and (min-width: 1024px) {
  .mt-15-md {
    margin-top: 15px !important;
  }
}
.mb-20 {
  margin-bottom: 20px !important;
}
@media screen and (min-width: 1024px) {
  .mb-20-md {
    margin-bottom: 20px !important;
  }
}
.mt-20 {
  margin-top: 20px !important;
}
@media screen and (min-width: 1024px) {
  .mt-20-md {
    margin-top: 20px !important;
  }
}
.mb-25 {
  margin-bottom: 25px !important;
}
@media screen and (min-width: 1024px) {
  .mb-25-md {
    margin-bottom: 25px !important;
  }
}
.mt-25 {
  margin-top: 25px !important;
}
@media screen and (min-width: 1024px) {
  .mt-25-md {
    margin-top: 25px !important;
  }
}
.mb-30 {
  margin-bottom: 30px !important;
}
@media screen and (min-width: 1024px) {
  .mb-30-md {
    margin-bottom: 30px !important;
  }
}
.mt-30 {
  margin-top: 30px !important;
}
@media screen and (min-width: 1024px) {
  .mt-30-md {
    margin-top: 30px !important;
  }
}
.mb-35 {
  margin-bottom: 35px !important;
}
@media screen and (min-width: 1024px) {
  .mb-35-md {
    margin-bottom: 35px !important;
  }
}
.mt-35 {
  margin-top: 35px !important;
}
@media screen and (min-width: 1024px) {
  .mt-35-md {
    margin-top: 35px !important;
  }
}
.mb-40 {
  margin-bottom: 40px !important;
}
@media screen and (min-width: 1024px) {
  .mb-40-md {
    margin-bottom: 40px !important;
  }
}
.mt-40 {
  margin-top: 40px !important;
}
@media screen and (min-width: 1024px) {
  .mt-40-md {
    margin-top: 40px !important;
  }
}
.mb-45 {
  margin-bottom: 45px !important;
}
@media screen and (min-width: 1024px) {
  .mb-45-md {
    margin-bottom: 45px !important;
  }
}
.mt-45 {
  margin-top: 45px !important;
}
@media screen and (min-width: 1024px) {
  .mt-45-md {
    margin-top: 45px !important;
  }
}
.mb-50 {
  margin-bottom: 50px !important;
}
@media screen and (min-width: 1024px) {
  .mb-50-md {
    margin-bottom: 50px !important;
  }
}
.mt-50 {
  margin-top: 50px !important;
}
@media screen and (min-width: 1024px) {
  .mt-50-md {
    margin-top: 50px !important;
  }
}
.mb-55 {
  margin-bottom: 55px !important;
}
@media screen and (min-width: 1024px) {
  .mb-55-md {
    margin-bottom: 55px !important;
  }
}
.mt-55 {
  margin-top: 55px !important;
}
@media screen and (min-width: 1024px) {
  .mt-55-md {
    margin-top: 55px !important;
  }
}
.mb-60 {
  margin-bottom: 60px !important;
}
@media screen and (min-width: 1024px) {
  .mb-60-md {
    margin-bottom: 60px !important;
  }
}
.mt-60 {
  margin-top: 60px !important;
}
@media screen and (min-width: 1024px) {
  .mt-60-md {
    margin-top: 60px !important;
  }
}
.mb-65 {
  margin-bottom: 65px !important;
}
@media screen and (min-width: 1024px) {
  .mb-65-md {
    margin-bottom: 65px !important;
  }
}
.mt-65 {
  margin-top: 65px !important;
}
@media screen and (min-width: 1024px) {
  .mt-65-md {
    margin-top: 65px !important;
  }
}
.mb-70 {
  margin-bottom: 70px !important;
}
@media screen and (min-width: 1024px) {
  .mb-70-md {
    margin-bottom: 70px !important;
  }
}
.mt-70 {
  margin-top: 70px !important;
}
@media screen and (min-width: 1024px) {
  .mt-70-md {
    margin-top: 70px !important;
  }
}
.mb-75 {
  margin-bottom: 75px !important;
}
@media screen and (min-width: 1024px) {
  .mb-75-md {
    margin-bottom: 75px !important;
  }
}
.mt-75 {
  margin-top: 75px !important;
}
@media screen and (min-width: 1024px) {
  .mt-75-md {
    margin-top: 75px !important;
  }
}
.mb-80 {
  margin-bottom: 80px !important;
}
@media screen and (min-width: 1024px) {
  .mb-80-md {
    margin-bottom: 80px !important;
  }
}
.mt-80 {
  margin-top: 80px !important;
}
@media screen and (min-width: 1024px) {
  .mt-80-md {
    margin-top: 80px !important;
  }
}
.mb-85 {
  margin-bottom: 85px !important;
}
@media screen and (min-width: 1024px) {
  .mb-85-md {
    margin-bottom: 85px !important;
  }
}
.mt-85 {
  margin-top: 85px !important;
}
@media screen and (min-width: 1024px) {
  .mt-85-md {
    margin-top: 85px !important;
  }
}
.mb-90 {
  margin-bottom: 90px !important;
}
@media screen and (min-width: 1024px) {
  .mb-90-md {
    margin-bottom: 90px !important;
  }
}
.mt-90 {
  margin-top: 90px !important;
}
@media screen and (min-width: 1024px) {
  .mt-90-md {
    margin-top: 90px !important;
  }
}
.mb-95 {
  margin-bottom: 95px !important;
}
@media screen and (min-width: 1024px) {
  .mb-95-md {
    margin-bottom: 95px !important;
  }
}
.mt-95 {
  margin-top: 95px !important;
}
@media screen and (min-width: 1024px) {
  .mt-95-md {
    margin-top: 95px !important;
  }
}
.mb-100 {
  margin-bottom: 100px !important;
}
@media screen and (min-width: 1024px) {
  .mb-100-md {
    margin-bottom: 100px !important;
  }
}
.mt-100 {
  margin-top: 100px !important;
}
@media screen and (min-width: 1024px) {
  .mt-100-md {
    margin-top: 100px !important;
  }
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
@media screen and (min-width: 1024px) {
  .mr-5-md {
    margin-right: 5px !important;
  }
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
@media screen and (min-width: 1024px) {
  .mr-10-md {
    margin-right: 10px !important;
  }
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
@media screen and (min-width: 1024px) {
  .mr-15-md {
    margin-right: 15px !important;
  }
}
.ml-20 {
  margin-left: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
@media screen and (min-width: 1024px) {
  .mr-20-md {
    margin-right: 20px !important;
  }
}
.ml-25 {
  margin-left: 25px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
@media screen and (min-width: 1024px) {
  .mr-25-md {
    margin-right: 25px !important;
  }
}
.ml-30 {
  margin-left: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
@media screen and (min-width: 1024px) {
  .mr-30-md {
    margin-right: 30px !important;
  }
}
.ml-35 {
  margin-left: 35px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
@media screen and (min-width: 1024px) {
  .mr-35-md {
    margin-right: 35px !important;
  }
}
.ml-40 {
  margin-left: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
@media screen and (min-width: 1024px) {
  .mr-40-md {
    margin-right: 40px !important;
  }
}
.ml-45 {
  margin-left: 45px !important;
}
.mr-45 {
  margin-right: 45px !important;
}
@media screen and (min-width: 1024px) {
  .mr-45-md {
    margin-right: 45px !important;
  }
}
.ml-50 {
  margin-left: 50px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
@media screen and (min-width: 1024px) {
  .mr-50-md {
    margin-right: 50px !important;
  }
}
.ml-55 {
  margin-left: 55px !important;
}
.mr-55 {
  margin-right: 55px !important;
}
@media screen and (min-width: 1024px) {
  .mr-55-md {
    margin-right: 55px !important;
  }
}
.ml-60 {
  margin-left: 60px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
@media screen and (min-width: 1024px) {
  .mr-60-md {
    margin-right: 60px !important;
  }
}
.ml-65 {
  margin-left: 65px !important;
}
.mr-65 {
  margin-right: 65px !important;
}
@media screen and (min-width: 1024px) {
  .mr-65-md {
    margin-right: 65px !important;
  }
}
.ml-70 {
  margin-left: 70px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
@media screen and (min-width: 1024px) {
  .mr-70-md {
    margin-right: 70px !important;
  }
}
.ml-75 {
  margin-left: 75px !important;
}
.mr-75 {
  margin-right: 75px !important;
}
@media screen and (min-width: 1024px) {
  .mr-75-md {
    margin-right: 75px !important;
  }
}
.ml-80 {
  margin-left: 80px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
@media screen and (min-width: 1024px) {
  .mr-80-md {
    margin-right: 80px !important;
  }
}
.ml-85 {
  margin-left: 85px !important;
}
.mr-85 {
  margin-right: 85px !important;
}
@media screen and (min-width: 1024px) {
  .mr-85-md {
    margin-right: 85px !important;
  }
}
.ml-90 {
  margin-left: 90px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
@media screen and (min-width: 1024px) {
  .mr-90-md {
    margin-right: 90px !important;
  }
}
.ml-95 {
  margin-left: 95px !important;
}
.mr-95 {
  margin-right: 95px !important;
}
@media screen and (min-width: 1024px) {
  .mr-95-md {
    margin-right: 95px !important;
  }
}
.ml-100 {
  margin-left: 100px !important;
}
.mr-100 {
  margin-right: 100px !important;
}
@media screen and (min-width: 1024px) {
  .mr-100-md {
    margin-right: 100px !important;
  }
}
.margin-auto {
  margin: 0 auto !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
@media only screen and (min-width: 1050px) {
  .no-margin-md {
    margin: 0 !important;
  }
}
.no-margin-top {
  margin-top: 0 !important;
}
@media only screen and (min-width: 1050px) {
  .no-margin-top-md {
    margin-top: 0 !important;
  }
}
.clearfix:after,
.clearfix:before {
  content: '';
  display: block;
  clear: both;
}
.center-block {
  display: block;
  margin: 0 auto;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-inline-xs,
.visible-inline-sm,
.visible-inline-md,
.visible-inline-lg {
  display: none !important;
}
.visible-flex-xs,
.visible-flex-sm,
.visible-flex-md,
.visible-flex-lg {
  display: none !important;
}
@media only screen and (max-width: 799px) {
  .visible-xs {
    display: block !important;
  }
  .visible-inline-xs {
    display: inline-block !important;
  }
  .visible-flex-xs {
    display: flex !important;
  }
}
@media only screen and (min-width: 800px) {
  .visible-sm {
    display: block !important;
  }
  .visible-inline-sm {
    display: inline-block !important;
  }
  .visible-flex-sm {
    display: flex !important;
  }
}
@media only screen and (min-width: 1050px) {
  .visible-md {
    display: block !important;
  }
  .visible-inline-md {
    display: inline-block !important;
  }
  .visible-flex-md {
    display: flex !important;
  }
}
@media only screen and (min-width: 1800px) {
  .visible-lg {
    display: block !important;
  }
  .visible-inline-lg {
    display: inline-block !important;
  }
  .visible-flex-lg {
    display: flex !important;
  }
}
.hidden {
  display: none !important;
}
@media only screen and (max-width: 799px) {
  .hidden-xs {
    display: none !important;
  }
}
@media only screen and (min-width: 800px) {
  .hidden-sm {
    display: none !important;
  }
}
@media only screen and (min-width: 1050px) {
  .hidden-md {
    display: none !important;
  }
}
@media only screen and (min-width: 1800px) {
  .hidden-lg {
    display: none !important;
  }
}
.movertransvuelo {
  margin-top: 11px !important;
}
.block-center {
  margin: 0 auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: capitalize !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
@media only screen and (min-width: 1050px) {
  .text-left-md {
    text-align: left !important;
  }
}
.text-right {
  text-align: right !important;
}
@media only screen and (min-width: 1050px) {
  .text-right-md {
    text-align: right !important;
  }
}
.capitalize {
  text-transform: capitalize;
}
.c-pointer {
  cursor: pointer;
}
.icon {
  vertical-align: middle;
  line-break: 0;
  line-height: 0;
}
.icon--xs {
  font-size: 0.8em;
}
.icon--xs-sub:before {
  font-size: 0.8em;
}
.icon--sm {
  font-size: 1em;
}
.icon--sm-sub:before {
  font-size: 1em;
}
.icon--md {
  font-size: 1.25em;
}
.icon--md-sub:before {
  font-size: 1.25em;
}
.icon--lg {
  font-size: 1.5em;
}
.icon--lg-sub:before {
  font-size: 1.5em;
}
.icon--xl {
  font-size: 1.75em;
}
.icon--xl-sub:before {
  font-size: 1.75em;
}
.icon--xll {
  font-size: 2em;
}
.icon--xll-sub:before {
  font-size: 2em;
}
.separator {
  border: 0px;
  border-bottom: 0.5px solid rgba(68,68,68,0.2);
}
@media only screen and (min-width: 1050px) {
  .border-right-md {
    border-right: 0.5px solid rgba(68,68,68,0.2);
  }
}
