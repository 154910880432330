.navbar
  width: 100%
  position relative
  &__logo
    max-width 230px
    display: flex
    align-items: center
  &__wrap
    width: 100%
    display: flex
    position relative
    align-items: center
    justify-content: space-between
  &__btn
    display:none
  &__menu
    display flex
    justify-content: space-between
  &__container
    flex:1
    padding: 0 1.5rem
  &__logo-background
    background-color: #ffff
    padding: 0.3rem
  &__logo-mobile
    display: none
  &__logo-desk
    display: block

.ant-layout-header 
  //background: #1d2f47
  background: #0050B3

@media (max-width: 1600px)
    .navbar
      &__logo
        max-width 180px
      &__container
        flex:1
        padding: 0 0.5rem
      &__logo-background
        padding: 0.4rem
        background: #ffff
      &__logo-mobile
        display: none
      &__logo-desk
        display: block
    .custom_menu
      .ant-menu
        font-size: 12px 
      .ant-menu-item
        font-size: 12px
      .ant-menu-item, .ant-menu-submenu-title
        padding: 0 18px 0 5px
        font-size: 12px

@media (max-width: 1300px)
  .navbar
    &__logo-desk
      display: none
    &__logo-mobile
      max-width 200px
      display: block
    &__wrap
      justify-content: space-between
      padding: 0 1rem
    &__btn
      display block
      color:#fff
    &__logo-background
      background: #0050B3
  .navbar__menu
    display none

@media (max-width: 1023px)
  .navbar
    &__logo-desk
      display: none
    &__logo-mobile
      max-width 200px
      display: block
    &__wrap
      justify-content: space-between
      padding: 0 1rem
    &__btn
      display block
      color:#fff
    &__logo-background
      background: #0050B3
    
     
  .navbar__menu
    display none
+from-width(5)
  &.pull-right--sm
    float: right 
.custom_menu
  background: none
  border:0px
  line-height: 58px!important
  +from-width(5)
    color:#fff
  .ant-menu-item
    border-bottom: 0px
    top:0px
    &:hover
      border-bottom: 0px
      background: rgba(#fff,50%)!important
      color:#fff
  .ant-menu-submenu
    position:relative
    +from-width(5)
      &:after
        content:"\65"
        font-family: $iconFont
        position absolute
        right:4px
        font-size: 0.85rem
        top:0
  .ant-menu-submenu.ant-menu-submenu-active
    border-bottom: 0px
    background: rgba(#fff,50%)!important
  .ant-menu-item-selected
    border-bottom: 0px
    background: rgba(#fff,50%)!important
    & a 
      color:#fff
  .ant-menu-submenu
    border-bottom: 0px!important
    vertical-align: top
    color:#fff!important
  .ant-menu-submenu-title:hover
    color:#fff
.ant-menu-horizontal > .ant-menu-item > a
  color: #fff
  &:hover
    color:#fff
.drawer
  .ant-drawer-wrapper-body
    background: #222D32
  .ant-drawer-content-wrapper
    width 100%!important
    max-width 80%
    +from-width(4)
      width 100%!important
      max-width 360px
  .ant-menu-item
    padding-left: 20px!important
  .ant-drawer-header
    border-radius: 0px
    background-color: $layout-header-background
    .ant-drawer-title
      color:#ffff