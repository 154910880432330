.header {
  padding: 0 1rem 0 0 !important;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  border-bottom: 7px solid #ccdfff;
}
.header__caption {
  max-width: 100%;
}
.header__caption__wrap {
  background-color: rgba(0,80,179,0.55);
  padding: 0.3rem 10px;
  text-align: center;
  color: #fff;
}
@media only screen and (min-width: 63.9375em) {
  .header__caption {
    max-width: 1220px;
    margin: 0 auto;
    text-align: right;
    padding: 0 0.75rem;
  }
  .header__caption__wrap {
    display: inline-block;
  }
}
@media only screen and (min-width: 64em) {
  .header {
    flex-direction: row;
    padding: 0 1.5rem;
  }
}
.header__nav {
  width: 100%;
  padding: 0 1rem;
}
@media only screen and (min-width: 64em) {
  .header__nav {
    width: calc(100% - 250px);
  }
}
.header__counter {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 63.9375em) {
  .header__counter {
    padding-left: 30px;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .header__counter:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    height: 60%;
    width: 1px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
  }
}
.content {
  padding: 0.5rem 2rem 1rem;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (min-width: 48em) {
  .content {
    padding: 2.5rem 1rem;
    max-width: 90%;
  }
}
@media only screen and (min-width: 63.9375em) {
  .content {
    padding: 2.5rem 1rem;
    max-width: 85%;
  }
}
@media only screen and (min-width: 75em) {
  .content {
    padding: 0.5rem 1rem 2.5rem;
    max-width: 1220px;
  }
}
.footer {
  text-align: center;
  padding: 0.5rem 2rem;
  color: #fff;
}
@media (max-width: 1600px) {
  .header__counter {
    background-color: #0050b3;
    padding-left: 10px;
  }
}
@media (max-width: 1366px) {
  .header__counter {
    background-color: #0050b3;
    padding-left: 10px;
    font-size: 12px;
  }
}
