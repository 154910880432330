.display-justify-center {
    display: flex;
    justify-content: center;
}
.padding-form-whats-spin {
    padding-top: 1rem;
}
.display-justify-end-form-whats-btn{
    display: flex;
    justify-content: end;
}
.display-justify-center-form-whats-btn{
    display: flex;
    justify-content: center;
}
.padding-bottom-form-whats {
    padding-bottom: 1rem;
}

.padding-top-form-whats {
    padding-top: 1rem;
}

.display-align-center-justify-end {
    display: flex;
    align-self: center;
    justify-content: end;
}