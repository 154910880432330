@import '../../styles/index.styl'
.notfound
  background: #ECF0F5
  min-height: 100vh
  display: flex
  justify-content: center
  align-items: center
  padding: 2rem 0
  &__btn
    border-radius: 0px
    box-shadow: 0px 4px 8px rgba(#000,30%)
  &__caption
    padding: 0 1rem
    & h1,p
      color:$primaryColor