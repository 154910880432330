.form__group {
  margin-bottom: 15px;
}
@media only screen and (min-width: 1050px) {
  .form__group {
    margin-bottom: 20px;
  }
}
.form__group__label {
  font-weight: bold;
  font-size: 13px;
  font-weight: 600;
  line-height: 27px;
}
@media only screen and (min-width: 1050px) {
  .form__group__label {
    font-size: 14px;
  }
}
.form__group.no-margin {
  margin: 0px;
}
