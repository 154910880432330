// $flex-version = flex
// $support-for-ie = false
// $vendor-prefixes = official
rupture.enable-em-breakpoints = true
rupture.scale = 0px 400px 600px 768px 1023px 1200px 1400px
.header
  padding: 0 1rem 0 0 !important
  display: flex
  flex-direction: column
  height auto
  position relative
  border-bottom: 7px solid #CCDFFF
  &__caption
    max-width 100%
    &__wrap
      background-color: rgba($layout-header-background,55%)
      padding: 0.30rem 10px
      text-align: center
      color:#fff
    +from-width(5)
      max-width 1220px
      margin:0 auto
      text-align: right
      padding: 0 0.75rem
      &__wrap
        display inline-block
  +from-width(1024px)
    flex-direction: row
    padding: 0 1.5rem
    // 2.5rem 1rem
  &__nav
    width: 100%
    padding: 0 1rem
    +from-width(1024px)
      width: calc(100% - 250px)
  &__counter
    color:#fff
    position absolute
    top 50%
    left:50%
    transform: translate(-50%,-50%)
    display: flex
    align-items: center
    +from-width(5)
      padding-left: 30px
      position relative
      top:auto
      left auto
      transform: none
      &:before
        content:''
        position absolute
        display:block
        left:0
        height 60%
        width: 1px
        background: #fff
        top:50%
        transform: translateY(-50%)
.content
  padding: 0.5rem 2rem 1rem
  width: 100%
  margin:0 auto
  +from-width(4)
    padding: 2.5rem 1rem
    max-width 90%
  +from-width(5)
    padding: 2.5rem 1rem
    max-width 85%
  +from-width(6)
    padding: .5rem 1rem 2.5rem
    max-width 1220px
.footer
  text-align: center
  padding:0.5rem 2rem
  color:#fff
@media (max-width: 1600px)
   .header
      &__counter
        background-color: #0050B3
        padding-left: 10px
@media(max-width: 1366px)
 .header
  &__counter
    background-color: #0050B3
    padding-left: 10px
    font-size: 12px