html
  box-sizing: border-box
  margin-right: 0px!important
*,
*:after,
*:before
  padding: 0
  margin: 0
  box-sizing: inherit
img
	max-width : 100%
a
	text-decoration : none
	&:focus
		outline: none
body::-webkit-scrollbar
	display none
ol, ul
	list-style: none;
blockquote, q
	quotes: none
blockquote:before, blockquote:after,
q:before, q:after
	content: '';
	content: none;
table
	border-collapse: collapse
	border-spacing: 0
button
	background: none
	outline: none
	border 0px
	&:focus
		outline: none
input[type="text"],input[type="password"],input[type="number"],input[type="email"],input[type="submit"],select,textarea
	outline none
	&:focus
		outline none
select:disabled,input[type=text]:disabled,input[type=number]:disabled,input[type=password]:disabled,input[type=date]:disabled
	background fade($black,90%)
	border-color:fade($black,90%)
	cursor: not-allowed
	color:fade($black,90%)
	&::placeholder
		color:fade($black,90%)
select:focus
	outline: none
.danger-color
	color:$orange-color
.primary-color
	color:$primary-color
.primary-color-bg
	background-color:$primary-color
.btn-fix-width
	min-width: 115px
.btn-fix-width-excel
	background-color: #ffff
	min-width: 165px
	color: #227346
	border: #227346 1px solid
	&:hover
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		color: #227346
		border: #227346 1px solid
	&:active
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		color: #227346
		border: #227346 1px solid
	&:focus
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		color: #227346
		border: #227346 1px solid
.btn-fix-width-pdf
	background-color: #ffff
	min-width: 165px
	color: #FF0800
	border: #FF0800 1px solid
	&:hover
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
		color: #FF0800
		border: #FF0800 1px solid
	&:active
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
		color: #FF0800
		border: #FF0800 1px solid
	&:focus
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important
		color: #FF0800 !important
		border: #FF0800 1px solid !important

//hack for grid system auto flow
.no-wrap
	flex-flow: nowrap!important
@import './utils'