// @import '../../styles/index'
@keyframes fadeInDown
  from
    transform: translate(-50%, 0)
  to
    transform: translate(-50%, 75px)
    opacity: 1
.static_alert
  // color:$primary-color
  text-align: center
  width: 100%
  padding: 1rem
  &.square
    border-radius: 0px
  &.ant-alert-error .ant-alert-message
    color:$error-color
  &.ant-alert-info .ant-alert-message
    color:$primary-color
.alert_custom
  &--animate
    position fixed
    left:50%
    transform: translateX(-50%)
    max-width 90%
    z-index 900
    width: 100%
    opacity 0
    animation-duration: 1s
    animation-fill-mode: both
    animation-name: fadeInDown
    +from-width(6)
      max-width 75%