.card_custom
  width: 100%
  border-radius: 10px
  & .ant-card-head
    text-align: left 
    color:#fff
    border-radius: 10px 10px 0px 0px
    border:0px
    padding: 0px 25px
  & .ant-card-head .ant-card-head-wrapper .ant-card-head-title
    padding: 12px 0px
    // height 45px
  & .ant-card-body
    border:1px solid $secondary-color
    border-radius: 0px 0px 10px 10px
.card-dashboard
  box-shadow 0 1px 4px 0 rgba(0,0,0,0.2)
  & .ant-card-head
    background-color: #ffffff
    border-bottom: 0px
    position relative
  &--header
    content: ''
    text-transform: uppercase
    position: absolute
    width: calc(100% - 20px)
    height 100%
    border-bottom 0.5px solid rgba(68,68,68,0.2)
    line-height: 2.5
    left:50%
    transform: translateX(-50%)
    top:0
    color #444444
    font-weight bold
    letter-spacing 0
    & *
      text-transform: uppercase
