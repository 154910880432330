.notfound {
  background: #ecf0f5;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}
.notfound__btn {
  border-radius: 0px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.3);
}
.notfound__caption {
  padding: 0 1rem;
}
.notfound__caption h1,
.notfound__caption p {
  color: #1890ff;
}
