.channel-font-table-title {
    font-weight: 600;
    color: #ffff;
}

.channel-font-label {
    font-weight: 600;
}
.channel-font-size-label {
    font-size: 1.1rem;
}

.channel-table-bg .ant-table-thead .ant-table-cell {
    background-color: #0b53b7 !important;
}

.channel-display-align-center {
    display: flex;
    align-items: center;
}

.channel-pagination-bg .ant-pagination-item-active {
    background: #ffff !important;
}

.channel-display-justify-end {
    display: flex;
    justify-content: end;
}