.form__group
  margin-bottom: 15px
  +from-width(5)
    margin-bottom: 20px
  &__label
    // color: #000000
    font-weight: bold
    font-size: 13px
    font-weight: 600
    line-height: 27px
    +from-width(5)
      font-size: 14px
  &.no-margin
    margin: 0px